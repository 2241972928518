export default {
  data() {
    return {
      platformList: [
        {
          label: 'Facebook',
          value: 'facebook',
          targetType: ['campaign', 'adset', 'ad'],
        },
        {
          label: 'Google',
          value: 'google',
        },
        {
          label: 'Tiktok',
          value: 'tiktok',
          targetType: ['campaign', 'adset'],
        },
        {
          label: 'Twitter',
          value: 'twitter',
        },
        {
          label: 'Line',
          value: 'line',
          targetType: ['campaign', 'adset'],
        },
        {
          label: 'Snapchat',
          value: 'snapchat',
          targetType: ['campaign', 'adset'],
        },
      ],
      targetTypeList: [
        {
          label: '投放中的广告系列',
          value: 'campaign',
          platform: ['facebook', 'tiktok', 'line', 'snapchat', 'google', 'twitter'],
        },
        {
          label: '投放中的广告组',
          value: 'adset',
          platform: ['facebook', 'tiktok', 'line', 'snapchat'],
        },
        {
          label: '投放中的广告',
          value: 'ad',
          platform: ['facebook'], // facebook平台支持广告
          disabled: true,
        },
      ],
      campaignCopyStatusList: [
        {
          label: '和原系列保持一致',
          value: true,
        },
        {
          label: '全部开启',
          value: false
        },
      ],
      attrFiltersList: [
        {
          desc: '系列',
          field: 'campaign_ids',
          operator: '包含',
          value: '',
          type: 'input',
          disabled: false,
        },
        {
          desc: '系列名称',
          field: 'campaign_name',
          operator: '包含字符',
          value: '',
          type: 'input',
          disabled: false,
        },
        {
          desc: '账户id',
          field: 'account_ids',
          operator: '包含',
          value: '',
          type: 'input',
          disabled: false,
        },
        {
          desc: '账户币种',
          field: 'account_currency',
          operator: '包含字符',
          value: '',
          type: 'select',
          listName: 'currencyList',
          labelName: 'name',
          valueName: 'code',
          tips: '账户币种：账户开户时选择的结算币种',
          disabled: false,
        },
        {
          desc: '地区',
          field: 'country_codes',
          operator: '包含',
          value: '',
          type: 'select',
          listName: 'countryList',
          labelName: 'name',
          valueName: 'code',
          tips: '地区：优先使用投放站点默认币种对应的区域；如为老单页使用商品币种对应的区域',
          disabled: false,
        },
        {
          desc: '团队',
          field: 'area_ids',
          operator: '包含',
          value: '',
          type: 'select',
          listName: 'teamIdList',
          labelName: 'name',
          valueName: 'id',
          tips: '团队：优先使用投放站点的团队；如为老单页使用商品所属团队',
          disabled: false,
        },
        {
          desc: '智能推广类型',
          field: 'smart_promotion_type',
          operator: '包含',
          value: '',
          type: 'select',
          listName: 'promotionTypeList',
          labelName: 'label',
          valueName: 'value',
          disabled: false,
        },
        {
          desc: '产品id',
          field: 'product_ids',
          operator: '包含',
          value: '',
          type: 'input',
          disabled: false,
        },
        {
          desc: '商品id',
          field: 'sale_ids',
          operator: '包含',
          value: '',
          type: 'input',
          disabled: false,
        },
        {
          desc: '站点id',
          field: 'site_ids',
          operator: '包含',
          value: '',
          type: 'input',
          disabled: false,
        },
      ],
      promotionTypeList: [
        {
          label: '智能购物广告',
          value: 'AUTOMATED_SHOPPING_ADS',
        },
        {
          label: '引导创作',
          value: 'GUIDED_CREATION',
        },
        {
          label: '智能应用推广',
          value: 'SMART_APP_PROMOTION',
        },
      ],
      tipsMap: {
        country_codes: '地区：优先使用投放站点默认币种对应的区域；如为老单页使用商品币种对应的区域',
        account_currency: '账户币种：账户开户时选择的结算币种',
        area_ids: '团队：优先使用投放站点的团队；如为老单页使用商品所属团队',
        smart_promotion_type: '智能推广类型(仅facebook)',
        campaign_ids: '',
        account_ids: '',
      },
      attrFiltersOperatorList: [
        {
          label: '包含',
          value: '包含',
        },
        {
          label: '排除',
          value: '不包含',
        },
      ],
      attrFiltersCampaignNameOperatorList: [
        {
          label: '包含字符',
          value: '包含字符',
        },
        {
          label: '不包含字符',
          value: '不包含字符',
        },
      ],
      operatorList: ['大于', '大于等于', '小于', '小于等于', '等于', '不等于'],
      dateList: [
        '当天',
        '最近2天',
        '最近3天',
        '最近4天',
        '最近5天',
        '最近6天',
        '最近7天',
        '最近14天',
        '最近30天',
        '当月',
        '上1小时',
        '当前小时',
        '最近2小时',
        '最近3小时',
        '最近4小时',
        '最近5小时',
        '最近6小时',
      ],
      dateInsideList: ['默认', '当天', '最近2天', '最近3天', '最近4天', '最近5天', '最近6天', '最近7天', '当月'],
      dateOrderList: [
        '默认',
        '当天',
        '最近2天',
        '最近3天',
        '最近4天',
        '最近5天',
        '最近6天',
        '最近7天',
        '当月',
        '上1小时',
      ],
      actionTypeList: ['仅发送通知 ', '暂停 ', '开启', '复制', '调整日预算', '调整竞价'],
      budgetList: ['提高', '降低'],
      weekList: [
        {
          label: '周一',
          value: 1,
        },
        {
          label: '周二',
          value: 2,
        },
        {
          label: '周三',
          value: 3,
        },
        {
          label: '周四',
          value: 4,
        },
        {
          label: '周五',
          value: 5,
        },
        {
          label: '周六',
          value: 6,
        },
        {
          label: '周日',
          value: 0,
        },
      ],
      statusList: ['开启', '暂停'],
      changeTypeList: [
        {
          label: '金额（账户币种）',
          value: 'AMOUNT',
        },
        {
          label: '百分比',
          value: 'PERCENT',
        },
        {
          label: '设置成',
          value: 'SET',
        },
      ],
      //系列和组都有的条件
      commonMetricFiltersList: [
        {
          desc: '加购',
          field: 'checkout',
          operator: '大于',
          value: 1,
          disabled: true,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '订单',
          field: 'order_count',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '花费',
          field: 'spend',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '订单成本',
          field: 'cp_order',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '加购成本',
          field: 'cp_checkout',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '毛利率',
          field: 'pre_profit_rate',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '点击',
          field: 'clicks',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '展示',
          field: 'impressions',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '点击率',
          field: 'ctr',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '点击成本',
          field: 'cpc',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '千次展示成本',
          field: 'cpm',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '日预算',
          field: 'daily_budget',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isNeedDate: false,
        },
        {
          desc: '目标出价',
          field: 'target_cpa',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isNeedDate: false,
        },
        {
          desc: '竞价',
          field: 'bid_ammount',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isNeedDate: false, //在表达式的选项中是否需要搭配时间
        },
        {
          desc: '上线天数',
          field: 'upline_days',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isHideExpression: true, //在表达式的选项中是否需要隐藏
          isInt: true,
        },
        {
          desc: '开始结账数(tt商店)',
          field: 'onsite_initiate_checkout_count',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '开始结账成本(tt商店)',
          field: 'cp_onsite_initiate_checkout_count',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '订单数(tt商店)',
          field: 'onsite_shopping',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '订单成本(tt商店)',
          field: 'cp_onsite_shopping',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '总收入(tt商店)',
          field: 'total_onsite_shopping_value',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: 'ROI(tt商店)',
          field: 'onsite_shopping_roi',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '平均订单价值(tt商店)',
          field: 'value_per_onsite_shopping',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '转化率(tt商店)',
          field: 'onsite_shopping_rate',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '暂停持续时长(小时)',
          field: 'disable_duration_hours',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '实时转化(tt)',
          field: 'real_time_conversion',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '实时转化成本(tt)',
          field: 'cp_real_time_conversion',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
      ],
      //针对广告组有的条件
      adsetMetricFiltersList: [
        {
          desc: '系列加购',
          field: 'camp_checkout',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '系列订单',
          field: 'camp_order_count',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '系列花费',
          field: 'camp_spend',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '系列订单成本',
          field: 'camp_cp_order',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '系列加购成本',
          field: 'camp_cp_checkout',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '系列毛利率',
          field: 'camp_pre_profit_rate',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '系列点击',
          field: 'camp_clicks',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '系列展示',
          field: 'camp_impressions',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '系列点击率',
          field: 'camp_ctr',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '系列点击成本',
          field: 'camp_cpc',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '系列千次展示成本',
          field: 'camp_cpm',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '系列暂停持续时长(小时)',
          field: 'camp_disable_duration_hours',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '系列开始结账数(tt商店)',
          field: 'camp_onsite_initiate_checkout_count',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '系列开始结账成本(tt商店)',
          field: 'camp_cp_onsite_initiate_checkout_count',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '系列订单数(tt商店)',
          field: 'camp_onsite_shopping',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '系列订单成本(tt商店)',
          field: 'camp_cp_onsite_shopping',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '系列总收入(tt商店)',
          field: 'camp_total_onsite_shopping_value',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '系列ROI(tt商店)',
          field: 'camp_onsite_shopping_roi',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '系列平均订单价值(tt商店)',
          field: 'camp_value_per_onsite_shopping',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '系列转化率(tt商店)',
          field: 'camp_onsite_shopping_rate',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '系列实时转化(tt)',
          field: 'camp_real_time_conversion',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '系列实时转化成本(tt)',
          field: 'camp_cp_real_time_conversion',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
      ],
      adMetricFiltersList: [
        {
          desc: '广告组加购',
          field: 'adset_checkout',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '广告组订单',
          field: 'adset_order_count',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '广告组花费',
          field: 'adset_spend',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '广告组订单成本',
          field: 'adset_cp_order',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '广告组加购成本',
          field: 'adset_cp_checkout',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '广告组毛利率',
          field: 'adset_pre_profit_rate',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '广告组点击',
          field: 'adset_clicks',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '广告组展示',
          field: 'adset_impressions',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '广告组点击率',
          field: 'adset_ctr',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '广告组点击成本',
          field: 'adset_cpc',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '广告组千次展示成本',
          field: 'adset_cpm',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '广告组暂停持续时长(小时)',
          field: 'adset_disable_duration_hours',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '广告组开始結账数(tt商店)',
          field: 'adset_onsite_initiate_checkout_count',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '广告组开始結账成本(tt商店)',
          field: 'adset_cp_onsite_initiate_checkout_count',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '广告组订单数(tt商店)',
          field: 'adset_onsite_shopping',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '广告组订单成本(tt商店)',
          field: 'adset_cp_onsite_shopping',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '广告组总收入(tt商店)',
          field: 'adset_total_onsite_shopping_value',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '广告组ROI(tt商店)',
          field: 'adset_onsite_shopping_roi',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '广告组平均订单价值(tt商店)',
          field: 'adset_value_per_onsite_shopping',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '广告组转化率(tt商店)',
          field: 'adset_onsite_shopping_rate',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
        {
          desc: '广告组实时转化(tt)',
          field: 'adset_real_time_conversion',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
          isInt: true,
        },
        {
          desc: '广告组实时转化成本(tt)',
          field: 'adset_cp_real_time_conversion',
          operator: '大于',
          value: 1,
          date_preset: '默认',
          value_type: 'normal',
        },
      ],
    };
  },
};
